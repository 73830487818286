import React from 'react';
import {Typography} from 'antd';

const {Title} = Typography;
export default class ProjectPage extends React.Component {

    render(){
        return(
            <div >
                <Title style = {{textAlign: "center"}}>
                    TODO
                </Title>
                
            </div>
        )
    }

}
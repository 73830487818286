import React from "react";

export default class EuhcrePage extends React.Component{

    render(){
        return(
            <div>
                GENERAL KENOBI
            </div>
        )
    }
}
import React from "react";
//import ChessBoard from "./ChessBoard";
//import Board from "./Board";
import Game from "./Game";
export default class ChessPage extends React.Component{

    render(){
        return(
            <div>
                <Game/>
            </div>
        )
    }
}